import { useState, useEffect } from "react";
import { Link, Route, Router, Switch, useLocation } from "wouter";
import Navbar from "./components/Navbar";
import { ReactComponent as Logo } from "./logo.svg";
import cc from "classcat";

import Archive from "./routes/Archive";
import Bundespreis from "./routes/Bundespreis";
import Exhibition from "./routes/Exhibition";
import Nominees from "./routes/Nominees";
import Catalog from "./routes/Catalog";
import Imprint from "./routes/Imprint";
import Pile from "./components/Pile";
import RoumaldEmphasis from "./components/RoumaldEmphasis";
import ArtistNavbar from "./components/ArtistNavbar";
import ArtistBig from "./components/ArtistBig";
import useProjectsData from "./hooks/useProjectsData";
import ArtistInFocus from "./components/ArtistInFocus";
import ArtistMobile from "./components/ArtistMobile";

function App() {
  const [location, setLocation] = useLocation();
  const [transitionStage, setTransistionStage] = useState("slideInRoute");
  const [displayLocation, setDisplayLocation] = useState(location);
  const [logoFaded, setLogoFaded] = useState(false);

  const [centerArtistId, setCenterArtist] = useState();

  const [showArtistCard, setShowArtistCard] = useState(null);

  const winners = useProjectsData();

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("slideOutRoute");
  }, [location, displayLocation]);

  return (
    <Router base={process.env.PUBLIC_URL}>
      <div className="flex h-screen flex-col bg-lightest-gray">
        <main className="relative grow">
          <div className="absolute inset-0 grid place-items-center items-center justify-items-center gap-6 overflow-hidden bg-lightest-gray xl:px-12">
            <Link href="/">
              <Logo
                className={cc([
                  "max-h-[75%] w-full p-4 transition-opacity duration-1000 ease-in-out sm:w-3/4 sm:p-0",
                  {
                    "opacity-[15%]": logoFaded,
                  },
                ])}
                onAnimationEnd={() => {
                  setLogoFaded(true);
                }}
              />
            </Link>
          </div>
          <div className="absolute inset-0 overflow-hidden">
            {!centerArtistId && (
              <div
                className={cc([
                  "absolute inset-0 grid grid-cols-2 justify-center pt-8 pb-24 transition-all duration-500 sm:px-8 md:gap-4 md:pb-16 lg:h-screen lg:grid-cols-12 lg:px-12",
                  {
                    "translate-y-48 overflow-hidden": !logoFaded,
                    " overflow-auto": logoFaded,
                  },
                ])}
              >
                {!centerArtistId && (
                  <>
                    <div className="col-span-full mb-4 flex w-full flex-col items-center justify-end pt-[65vh] text-2xl transition-all lg:hidden">
                      <RoumaldEmphasis>Preisträger*Innen</RoumaldEmphasis>
                      <RoumaldEmphasis>↓</RoumaldEmphasis>
                    </div>
                    {logoFaded &&
                      winners.map((artist) => (
                        <Pile
                          key={artist.id}
                          artist={artist}
                          id={artist.id}
                          images={artist.images}
                          name={artist.name}
                          centerArtist={centerArtistId}
                          setCenterArtist={setCenterArtist}
                        />
                      ))}
                  </>
                )}
              </div>
            )}
            {centerArtistId && (
              <ArtistInFocus
                artistId={centerArtistId}
                winners={winners}
                setArtist={setCenterArtist}
                showArtistCard={showArtistCard}
                setShowArtistCard={setShowArtistCard}
                projectTitle="title"
                description="desc"
                prev={
                  winners[winners.findIndex((w) => w.id === centerArtistId) - 1]
                    ?.id ?? winners.slice(-1)[0].id
                }
                next={
                  winners[winners.findIndex((w) => w.id === centerArtistId) + 1]
                    ?.id ?? winners[0].id
                }
              />
            )}
            <div
              id="main-menu"
              className={cc([
                "absolute grid h-screen grid-cols-2 overflow-auto pt-8 pb-24 transition-all duration-700 sm:px-8 md:grid-cols-12 md:pb-16 lg:px-12",
                `${transitionStage}`,
                {
                  "top-0":
                    location !==
                    (process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"),
                },
              ])}
              onClick={(event) => {
                if (
                  event.target === event.currentTarget &&
                  location !==
                    (process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/")
                ) {
                  setLocation(
                    process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/"
                  );
                }
              }}
              onAnimationEnd={() => {
                if (transitionStage === "slideOutRoute") {
                  setTransistionStage("slideInRoute");
                  setDisplayLocation(location);
                }
              }}
            >
              <Switch location={displayLocation}>
                <Route path={`${process.env.PUBLIC_URL}/bundespreis`}>
                  <Bundespreis />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/ausstellung`}>
                  <Exhibition />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/nominierte`}>
                  <Nominees />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/katalog`}>
                  <Catalog />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/archiv`}>
                  <Archive />
                </Route>
                <Route path={`${process.env.PUBLIC_URL}/impressum`}>
                  <Imprint />
                </Route>
              </Switch>
            </div>
          </div>
        </main>
        {!centerArtistId && (
          <Navbar
            menuContentOpen={
              location !==
              (process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/")
            }
            closeMenuContent={() =>
              setLocation(process.env.PUBLIC_URL ? process.env.PUBLIC_URL : "/")
            }
          />
        )}
        {centerArtistId && (
          <ArtistNavbar
            setCenterArtist={setCenterArtist}
            showArtistCard={showArtistCard}
            setShowArtistCard={setShowArtistCard}
          />
        )}
      </div>
    </Router>
  );
}

export default App;
