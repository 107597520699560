import ContentOverlay from "../components/ContentOverlay";
import RoumaldEmphasis from "../components/RoumaldEmphasis";

export default function Archive() {
  return (
    <ContentOverlay className="col-span-full self-end md:col-span-6 md:col-start-7 lg:col-span-4 lg:col-start-9">
      <h2 className="mb-4">
        <RoumaldEmphasis>ARCHIV</RoumaldEmphasis>
      </h2>
      <p className="mb-4">
        Die Medien, die den Wettbewerb begleiten &ndash; Katalog, Webseite,
        Plakat &ndash; werden jedes Mal von einer der teilnahmeberechtigten
        Hochschulen gestaltet. Unter den folgenden Links sind die
        Internetauftritte der letzten drei Wettbewerbsausgaben archiviert.
        <ul className="mt-4">
          <li>
            <a
              href="https://www.kunst-wettbewerb.de/archiv/2021/index.html"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              25. Bundeswettbewerb 2021/2022
            </a>
          </li>
          <li>
            <a
              href="https://www.kunst-wettbewerb.de/archiv/2019/index.html"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              24. Bundeswettbewerb 2019/2020
            </a>
          </li>
          <li>
            <a
              href="https://www.kunst-wettbewerb.de/archiv/2017/index.php"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              23. Bundeswettbewerb 2017/2018
            </a>
          </li>
        </ul>
      </p>
    </ContentOverlay>
  );
}
