import { useRoute, Link } from "wouter";
import cc from "classcat";

export default function NavLink(props) {
  const [isActive] = useRoute(props.href);
  const styles = props.collapseMode
    ? props.open
      ? { transform: "translateY(0px)" }
      : isActive
      ? { transform: `translateY(-${props.idx * 56}px` }
      : { transform: `translateY(${80 - props.idx * 16}px` }
    : {};

  return (
    <Link {...props}>
      <button
        className={cc([
          props.className,
          "uppercase transition-all duration-1000 hover:font-roumald-bolditalic",
          {
            "opacity-0": !props.open && !isActive && props.collapseMode,
            "opacity-100": props.open && props.collapseMode,
            "h-full w-full":
              (!isActive && props.collapseMode) || !props.collapseMode,
            "font-roumald-bolditalic": isActive,
            "font-roumald-bold": !isActive,
            "pointer-events-none": !props.open && props.collapseMode,
          },
        ])}
        tabIndex={!props.open && props.collapseMode ? "-1" : ""}
        style={styles}
      >
        {props.children}
      </button>
    </Link>
  );
}
