import ContentOverlay from "../components/ContentOverlay";
import RoumaldEmphasis from "../components/RoumaldEmphasis";

export default function Imprint() {
  return (
    <>
      <div className="pointer-events-none col-span-full flex flex-col md:col-span-6 lg:col-span-4">
        <div className="hidden flex-col md:flex lg:hidden">
          <ContentOverlay className="pointer-events-auto">
            <a href="https://www.bmbf.de" rel="noreferrer" target="_blank">
              <img
                className="mx-auto h-32"
                src={`${process.env.PUBLIC_URL}/BMBF.svg`}
                height="128"
                alt="gefördert vom Bundesministerium für Bildung und Forschung"
                title="Bundesministerium für Bildung und Forschung"
              />
            </a>
          </ContentOverlay>
          <ContentOverlay className="pointer-events-auto">
            <a
              href="https://www.studentenwerke.de/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className="mx-auto h-16 object-contain"
                src={`${process.env.PUBLIC_URL}/dsw.png`}
                height="64"
                alt="Deutsches Studierendenwerk"
                title="Deutsches Studierendenwerk"
              />
            </a>
          </ContentOverlay>
          <ContentOverlay className="pointer-events-auto">
            <a
              href="https://www.bundeskunsthalle.de"
              rel="noreferrer"
              target="_blank"
            >
              <img
                className="mx-auto my-6 h-16"
                src={`${process.env.PUBLIC_URL}/bundeskunsthalle.svg`}
                height="64"
                alt="Bundeskunsthalle"
                title="Kunst- und Ausstellungshalle der Bundesrepublik Deutschland GmbH©"
              />
            </a>
          </ContentOverlay>
        </div>
        <ContentOverlay className="pointer-events-auto">
          <h2 className="mb-4">
            <RoumaldEmphasis>Impressum</RoumaldEmphasis>
          </h2>
          <p className="mb-10">
            26. Bundeswettbewerb „Bundespreis für Kunststudierende“ gefördert
            vom Bundesministerium für Bildung und Forschung<br></br>
            <a
              href="https://www.bmbf.de"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              www.bmbf.de
            </a>
          </p>
          <h2 className="mb-4">
            <RoumaldEmphasis>Organisatorischer Träger</RoumaldEmphasis>
          </h2>
          <div className="mb-10">
            <address>
              Deutsches Studierendenwerk e.V.<br></br>
              Monbijouplatz 11<br></br>
              10178 Berlin
            </address>
            <a
              href="https://www.studentenwerke.de"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              www.studentenwerke.de
            </a>
          </div>
          <h2 className="mb-4">
            <RoumaldEmphasis>Durchführung der Ausstellung</RoumaldEmphasis>
          </h2>
          <address>
            Kunst- und Ausstellungshalle der Bundesrepublik Deutschland GmbH
            <br></br>
            Museumsmeile Bonn<br></br>Helmut-Kohl-Allee 4<br></br>53113 Bonn
          </address>
          <a
            href="https://www.bundeskunsthalle.de"
            rel="noreferrer"
            target="_blank"
            className="before:font-roumald-bold before:content-['↘']"
          >
            www.bundeskunsthalle.de
          </a>
        </ContentOverlay>
      </div>
      <div className="pointer-events-none col-span-full flex flex-col md:hidden lg:col-span-4 lg:col-start-5 lg:flex">
        <ContentOverlay className="pointer-events-auto">
          <a href="https://www.bmbf.de" rel="noreferrer" target="_blank">
            <img
              className="mx-auto h-32"
              src={`${process.env.PUBLIC_URL}/BMBF.svg`}
              height="128"
              alt="gefördert vom Bundesministerium für Bildung und Forschung"
              title="Bundesministerium für Bildung und Forschung"
            />
          </a>
        </ContentOverlay>
        <ContentOverlay className="pointer-events-auto">
          <a
            href="https://www.studentenwerke.de/"
            rel="noreferrer"
            target="_blank"
          >
            <img
              className="mx-auto h-16 object-contain"
              src={`${process.env.PUBLIC_URL}/dsw.png`}
              height="64"
              alt="Deutsches Studierendenwerk"
              title="Deutsches Studierendenwerk"
            />
          </a>
        </ContentOverlay>
        <ContentOverlay className="pointer-events-auto">
          <a
            href="https://www.bundeskunsthalle.de"
            rel="noreferrer"
            target="_blank"
          >
            <img
              className="mx-auto my-6 h-16"
              src={`${process.env.PUBLIC_URL}/bundeskunsthalle.svg`}
              height="64"
              alt="Bundeskunsthalle"
              title="Kunst- und Ausstellungshalle der Bundesrepublik Deutschland GmbH©"
            />
          </a>
        </ContentOverlay>
        <ContentOverlay className="pointer-events-auto hidden lg:block">
          <h2 className="mb-4">
            <RoumaldEmphasis>Konzeption und Gestaltung</RoumaldEmphasis>
          </h2>
          <h3>Katalog, Plakat und Webseite</h3>
          <p className="mb-10">
            Manuel Bauer, Jonathan Körner, Nicolai Zeiher, Staatliche Akademie
            der Bildenden Künste Stuttgart
          </p>
          <h2 className="mb-4">
            <RoumaldEmphasis>Umsetzung der Webseite</RoumaldEmphasis>
          </h2>
          <p>Stefan Avramescu</p>
        </ContentOverlay>
      </div>
      <div className="pointer-events-none col-span-full flex flex-col md:col-span-6 md:col-start-7 lg:col-span-4 lg:col-start-9">
        <ContentOverlay className="pointer-events-auto lg:hidden">
          <h2 className="mb-4">
            <RoumaldEmphasis>Konzeption und Gestaltung</RoumaldEmphasis>
          </h2>
          <h3>Katalog, Plakat und Webseite</h3>
          <p className="mb-10">
            Manuel Bauer, Jonathan Körner, Nicolai Zeiher, Staatliche Akademie
            der Bildenden Künste Stuttgart
          </p>
          <h2 className="mb-4">
            <RoumaldEmphasis>Umsetzung der Webseite</RoumaldEmphasis>
          </h2>
          <p>Stefan Avramescu</p>
        </ContentOverlay>
        <ContentOverlay className="pointer-events-auto">
          <h2 className="mb-4">
            <RoumaldEmphasis>Datenschutzerklärung</RoumaldEmphasis>
          </h2>
          <p className="mb-4">
            Wir freuen uns, dass Sie unsere Webseite www.kunst-wettbewerb.de
            besuchen und sich für den Bundeswettbewerb „Bundespreis für
            Kunststudierende“ interessieren. Der Schutz Ihrer personenbezogenen
            Daten, die wir bei Ihrem Besuch auf unserer Website speichern, ist
            uns wichtig. Verantwortlich für diese Seite ist das Deutsches
            Studierendenwerk. Unserer ausführliche Datenschutzerklärung, in der
            wir Sie allgemein über die Verarbeitung Ihrer personenbezogenen
            Daten informieren, soweit sie überhaupt beim Besuch unserer Seite
            anfallen, sowie einen Überblick über Ihre Rechte aus den
            Datenschutzgesetzen finden Sie{" "}
            <a
              href="https://www.studentenwerke.de/de/content/datenschutzerkl%C3%A4rung"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              hier
            </a>
            .
          </p>
          <p className="mb-10">
            © Bundesministerium für Bildung und Forschung, Bonn 2023/2024
          </p>
          <h2 className="mb-4">
            <RoumaldEmphasis>Haftungshinweis</RoumaldEmphasis>
          </h2>
          <p>
            Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die
            Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
            ausschließlich deren Betreiber verantwortlich.
          </p>
        </ContentOverlay>
      </div>
    </>
  );
}
