import ContentOverlay from "../components/ContentOverlay";
import RoumaldEmphasis from "../components/RoumaldEmphasis";

export default function Catalog() {
  return (
    <ContentOverlay className="col-span-full self-end md:col-span-6 md:col-start-7 lg:col-span-4 lg:col-start-7">
      <h2 className="mb-4">
        <RoumaldEmphasis>Katalog</RoumaldEmphasis>
      </h2>
      <p>
        Zu jedem Wettbewerb erscheint ein Katalog, der das künstlerische
        Schaffen der Preisträger*innen dokumentiert. Der Katalog sowie weitere
        Wettbewerbsmedien werden von einer der teilnehmenden Kunsthochschulen
        gestaltet. Beim 26. Bundeswettbewerb übernimmt dies die Staatliche
        Akademie der Bildenden Künste Stuttgart.
      </p>
      <p className="mt-4">
        Der Katalog erscheint zur Ausstellungseröffnung am{" "}
        <time dateTime="2023-10-26">26. Oktober 2023</time> und kann dann bis
        zum Ende der Ausstellung am{" "}
        <time dateTime="2024-01-07">7. Januar 2024</time> in der Buchhandlung
        Walther König an der Bundeskunsthalle erworben werden. Darüber hinaus
        können Sie den Katalog beim Deutschen Studierendenwerk bestellen, ebenso
        wie die Kataloge vorheriger Wettbewerbsdurchgänge <br></br>(Kontakt:{" "}
        <a
          href="mailto:kultur@studentenwerke.de?subject=Bundespreis für Kunst Katalog"
          rel="noreferrer"
          target="_blank"
          className="before:font-roumald-bold before:content-['↘']"
        >
          kultur@studentenwerke.de
        </a>
        ).
      </p>
    </ContentOverlay>
  );
}
