import cc from "classcat";

export default function NavButton(props) {
  const styles = props.collapseMode
    ? props.open
      ? { transform: "translateY(0px)" }
      : props.isActive
      ? { transform: `translateY(-${props.idx * 56}px` }
      : { transform: `translateY(${80 - props.idx * 16}px` }
    : {};

  return (
    <button
      className={cc([
        props.className,
        "uppercase transition-all duration-1000 hover:font-roumald-bolditalic",
        {
          "opacity-0": !props.open && !props.isActive && props.collapseMode,
          "opacity-100": props.open && props.collapseMode,
          "h-full w-full":
            (!props.isActive && props.collapseMode) || !props.collapseMode,
          "font-roumald-bolditalic": props.isActive,
          "font-roumald-bold": !props.isActive,
          "pointer-events-none": !props.open && props.collapseMode,
        },
      ])}
      onClick={props.onClick}
      tabIndex={!props.open && props.collapseMode ? "-1" : ""}
      style={styles}
    >
      {props.children}
    </button>
  );
}
