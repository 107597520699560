import ContentOverlay from "../components/ContentOverlay";
import RoumaldEmphasis from "../components/RoumaldEmphasis";

export default function Exhibition() {
  return (
    <ContentOverlay className="col-span-full self-end md:col-span-6 md:col-start-3 lg:col-span-4 lg:col-start-3">
      <h2 className="">
        <RoumaldEmphasis>
          Ausstellungseröffnung und Preisverleihung
        </RoumaldEmphasis>
      </h2>
      <p>
        <br />
        <time dateTime="2023-10-26T19:00:00.000">26. Oktober 2023, 19 Uhr</time>
      </p>
      <h2 className="mt-8">
        <RoumaldEmphasis>Ausstellung</RoumaldEmphasis>
      </h2>
      <p>
        <br />
        <time dateTime="2023-10-27">27. Oktober 2023</time> &ndash;{" "}
        <time dateTime="2024-01-07">7. Januar 2024</time>
        <address className="not-italic">
          Bundeskunsthalle
          <br />
          Museumsmeile Bonn
          <br /> Helmut-Kohl-Allee 4<br /> 53113 Bonn
          <br />
          <a
            href="https://www.bundeskunsthalle.de"
            rel="noreferrer"
            target="_blank"
            className="before:font-roumald-bold before:content-['↘']"
          >
            www.bundeskunsthalle.de
          </a>
        </address>
      </p>
      <h2 className="mt-8">
        <RoumaldEmphasis>Öffnungszeiten</RoumaldEmphasis>
      </h2>
      <p>
        <br />
        Di.&ndash;So. & feiertags 10&ndash;19 Uhr
        <br /> Mi. 10&ndash;21 Uhr
      </p>
    </ContentOverlay>
  );
}
