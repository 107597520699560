import ContentOverlay from "../components/ContentOverlay";
import RoumaldEmphasis from "../components/RoumaldEmphasis";

export default function Nominees() {
  const winners = [
    { name: "Talya Feldman", url: "https://www.talyafeldman.net/" },
    { name: "Michael Fink", url: "" },
    { name: "Rahel Goetsch", url: "" },
    { name: "Hanna Kučera", url: "https://hannakucera.com/" },
    { name: "Su Yu Hsin", url: "https://www.suyuhsin.net/CV" },
    {
      name: (
        <>
          <span className="italic">óstov collective:</span>
          <ul>
            <li>Anna Perepechai</li>
            <li>Emilia Sladek</li>
            <li>Elza Gubanova</li>
            <li>Leon Seidel</li>
          </ul>
        </>
      ),
      url: "https://www.instagram.com/ostov.collective/",
    },
  ];

  const nominees = [
    {
      name: "Rebecca Arnold",
      url: "https://www.instagram.com/rebecca_arnold_studio/",
    },
    { name: "Seunghoon Baek", url: "" },
    { name: "Christoph Büttner", url: "" },
    { name: "Ilayda Cakir", url: "" },
    { name: "Tobias Doerr", url: "https://tobiasmariadoerr.de/" },
    { name: "Hannes Dünnebier", url: "https://hannesduennebier.com/" },
    { name: "Ruben Einsmann", url: "https://www.instagram.com/rubenshamoni/" },
    { name: "Lizzy Ellbrück", url: "" },
    { name: "Veronika Haller ", url: "http://veronikahaller.net/" },
    { name: "Zishi Han ", url: "" },
    { name: "Fabian Heller ", url: "" },
    { name: "Julien Hübsch", url: "http://www.julienhuebsch.com/" },
    { name: "Suse Itzel", url: "http://www.suse-itzel.info/" },

    { name: "Jakob Ketzel", url: "" },
    { name: "Coretta Klaue", url: "" },
    { name: "Ana Kostova", url: "https://www.instagram.com/ana.kostova" },
    { name: "Lena Krüger", url: "https://www.lsnkgr.de/" },
    { name: "Malin Kuht", url: "" },
    { name: "Ivan Labalestra", url: "" },
    { name: "Swan Lee", url: "http://www.swanlee.net/" },
    {
      name: "Atsushi Mannami",
      url: "https://www.instagram.com/atsushi_man_nami/",
    },
    { name: "Severine Meier", url: "https://severinehenriettemeier.com/" },
    { name: "Paul Millet", url: "" },
    { name: "Julius Nägele", url: "" },
    { name: "Ludwig Neumayr", url: "https://www.ludwigneumayr.com/" },
    { name: "Marlies Pahlenberg", url: "" },
    { name: "Paule Potulski", url: "" },
    { name: "Marie Heleen Samrotzki", url: "https://mariesamrotzki.net/" },
    { name: "Magdalena Schwitzer", url: "" },
    { name: "Emika Sekine", url: "" },
    { name: "Laura Sperl", url: "https://laurasperl.com/" },
    { name: "Lili Süper", url: "https://www.instagram.com/lili.sueper/" },
    { name: "Theresa Tuffner", url: "http://www.theresatuffner.com/" },
    { name: "Tatjana Vall", url: "" },
    { name: "Justin Urbach", url: "" },
    { name: "André Wendland", url: "" },
    { name: "Simon Wienk-Borgert", url: "" },
    { name: "Alla Zakiullina", url: "http://allapopp.com/" },
  ];

  const nomineeGroups = [
    {
      name: (
        <>
          <span className="italic">sustainable photography:</span>
          <ul>
            <li>Pierre Baumann</li>
            <li>Patricia Capalija</li>
            <li>Josephine Gerhardt</li>
            <li>Anna Lina Helsen</li>
            <li>Sven Knahl</li>
            <li>Rui Zheeng</li>
            <li>Ying Wang</li>
          </ul>
        </>
      ),
    },
    {
      name: (
        <>
          Kai Fischer mit{" "}
          <a
            href="http://www.s-o-av.de/"
            rel="noreferrer"
            target="_blank"
            className="before:font-roumald-bold before:content-['↘']"
          >
            Team, Helen Weber, Fabian Widukind Penzkofer, Alexander Sowa
          </a>
        </>
      ),
    },
    {
      name: (
        <a
          href="http://www.alisonkuhn.com/"
          rel="noreferrer"
          target="_blank"
          className="before:font-roumald-bold before:content-['↘']"
        >
          Alison Kuhn mit Team
        </a>
      ),
    },
    {
      name: (
        <ul>
          <li>
            <a
              href="https://www.heysun.xyz/"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              Hyeson Jeong
            </a>
          </li>
          <li>
            <a
              href="https://seongminyuk.com/"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              Seongmin Yuk
            </a>
          </li>
        </ul>
      ),
    },
    {
      name: (
        <ul>
          <li>Klara Kirsch</li>
          <li>Milena Bühring</li>
          <li>Lisa Kaschubat</li>
        </ul>
      ),
    },
    {
      name: (
        <ul>
          <li>
            <a
              href="https://tatjanavall.com/"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              Tatjana Vall
            </a>
          </li>
          <li>
            <a
              href="https://justinurbach.de/"
              rel="noreferrer"
              target="_blank"
              className="before:font-roumald-bold before:content-['↘']"
            >
              Justin Urbach
            </a>
          </li>
        </ul>
      ),
    },
  ];

  return (
    <>
      <div className="pointer-events-none col-span-full md:col-span-6 md:flex md:h-[calc(100vh_-_6rem)] md:items-end lg:col-span-4">
        <ContentOverlay className="pointer-events-auto">
          <p>
            Alle 24 in der Rektorenkonferenz der deutschen Kunsthochschulen
            vertretenen Akademien, Hochschulen und Universitäten nehmen am
            Wettbewerb teil und nominieren jeweils zwei ihrer Studierenden oder
            Studierendenteams. Aus den Nominierten wählt eine unabhängige
            Fachjury anhand von Portfolios bis zu acht Preisträger*innen aus.
          </p>
        </ContentOverlay>
      </div>
      <div className="col-span-1 flex flex-col md:col-span-3 md:col-start-7 lg:col-start-5">
        <ContentOverlay>
          <h2 className="mb-4">
            <RoumaldEmphasis>
              Preisträger
              <br />
              *innen
            </RoumaldEmphasis>
          </h2>
          <ul>
            {winners.map((w, idx) => (
              <li key={idx}>
                {w.url ? (
                  <a
                    href={w.url}
                    rel="noreferrer"
                    target="_blank"
                    className="before:font-roumald-bold before:content-['↘']"
                  >
                    {w.name}
                  </a>
                ) : (
                  w.name
                )}
              </li>
            ))}
          </ul>
        </ContentOverlay>
        <ContentOverlay>
          <h2 className="mb-4">
            <RoumaldEmphasis>Nominierte Gruppen</RoumaldEmphasis>
          </h2>
          {nomineeGroups.map((n, idx) => (
            <div className="mt-4" key={idx}>
              {n.name}
            </div>
          ))}
        </ContentOverlay>
      </div>
      <ContentOverlay className="col-span-1 col-start-2 md:col-span-3 md:col-start-10 lg:col-start-8">
        <h2 className="mb-4">
          <RoumaldEmphasis>Nominierte</RoumaldEmphasis>
        </h2>
        <ul>
          {nominees.map((n, idx) => (
            <li key={idx}>
              {n.url ? (
                <a
                  href={n.url}
                  rel="noreferrer"
                  target="_blank"
                  className="before:font-roumald-bold before:content-['↘']"
                >
                  {n.name}
                </a>
              ) : (
                n.name
              )}
            </li>
          ))}
        </ul>
      </ContentOverlay>
    </>
  );
}
