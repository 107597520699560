import ContentOverlay from "../components/ContentOverlay";
import RoumaldEmphasis from "../components/RoumaldEmphasis";

export default function Bundespreis() {
  return (
    <>
      <ContentOverlay className="col-span-full md:col-span-6 lg:col-span-4">
        <h2 className="mb-4">
          <RoumaldEmphasis className="font-roumald-bolditalic uppercase">
            26. Bundeswettbewerb des Bundesministeriums für Bildung und
            Forschung 2022/2023
          </RoumaldEmphasis>
        </h2>
        <p>
          Der{" "}
          <RoumaldEmphasis>Bundespreis für Kunststudierende</RoumaldEmphasis>{" "}
          will die Vielfalt der Ausbildung und der künstlerischen Positionen an
          den deutschen Kunsthochschulen zeigen. Er fördert gezielt
          herausragende Studierende und ermöglicht ihnen, professionelle
          Ausstellungserfahrung zu sammeln, ihre Arbeiten einer breiten
          Öffentlichkeit zu zeigen und Kontakte in den Kunstbetrieb zu knüpfen.
          Alle 24 in der Rektorenkonferenz der deutschen Kunsthochschulen
          vertretenen Akademien, Hochschulen und Universitäten nehmen am
          Wettbewerb teil und nominieren jeweils zwei ihrer Studierenden oder
          Studierendenteams. Aus den Nominierten wählt eine unabhängige Fachjury
          anhand von Portfolios bis zu acht Preisträger*innen aus. Die Jury des
          26. Bundeswettbewerbs bilden
          <ul className="pt-4">
            <li>
              <RoumaldEmphasis>Fatima Hellberg</RoumaldEmphasis>
              <br />
              Direktorin, Bonner Kunstverein
            </li>
            <li>
              <RoumaldEmphasis>Christiane Mennicke-Schwarz</RoumaldEmphasis>
              <br />
              Künstlerische Leitung, Kunsthaus Dresden
            </li>
            <li>
              <RoumaldEmphasis>Anna Nowak</RoumaldEmphasis>
              <br />
              Künstlerische Leitung, Kunsthaus Hamburg
            </li>
          </ul>
        </p>
      </ContentOverlay>
      <div className="pointer-events-none col-span-full flex flex-col md:col-span-6 lg:col-span-4">
        <ContentOverlay className="pointer-events-auto">
          <p>
            Der{" "}
            <RoumaldEmphasis>Bundespreis für Kunststudierende</RoumaldEmphasis>{" "}
            ist mit einem Preisgeld von 30.000 Euro dotiert. Zusätzlich
            unterstützt ein Produktionsstipendium von insgesamt 18.000 Euro die
            Künstler*innen dabei, Werke spezifisch für die Ausstellung zu
            realisieren.
          </p>
          <p className="pt-4">
            Der Wettbewerb begann im Jahr 1983 und wird alle zwei Jahre
            ausgelobt &ndash; in diesem Jahr findet er zum 26. Mal statt. Drei
            Institutionen tragen den Wettbewerb: das Bundesministerium für
            Bildung und Forschung fördert den Wettbewerb, das Deutsche
            Studierendenwerk organisiert und koordiniert ihn, die
            Bundeskunsthalle in Bonn zeigt die Ausstellung der
            Preisträger*innen. Bis zum Jahr 2015 firmierte der Wettbewerb unter
            dem Titel „Kunststudentinnen und Kunststudenten stellen aus“. Er
            wurde auf Initiative der beteiligten Kunsthochschulen neu konzipiert
            und in{" "}
            <RoumaldEmphasis>Bundespreis für Kunststudierende</RoumaldEmphasis>{" "}
            umbenannt. Die Ausstellung zum Wettbewerb ist seither den
            Preisträgerinnen und Preisträgern vorbehalten.
          </p>
        </ContentOverlay>
        <ContentOverlay className="hidden md:block lg:hidden">
          <p>
            Teilnahmeberechtigt sind die 24 Akademien, Hochschulen und
            Universitäten der Rektorenkonferenz der deutschen Kunsthochschulen
            (RKK):
          </p>
          <ul className="pt-4">
            <li>Universität der Künste Berlin</li>
            <li>Weißensee Kunsthochschule Berlin</li>
            <li>Hochschule für Bildende Künste Braunschweig</li>
            <li>Hochschule für Künste Bremen</li>
            <li>Hochschule für Bildende Künste Dresden</li>
            <li>Kunstakademie Düsseldorf</li>
            <li>
              Staatliche Hochschule für Bildende Künste &ndash; Städelschule,
              Frankfurt am Main
            </li>
            <li>Burg Giebichenstein Kunsthochschule Halle</li>
            <li>Hochschule für bildende Künste Hamburg</li>
            <li>Staatliche Akademie der Bildenden Künste Karlsruhe</li>
            <li>Staatliche Hochschule für Gestaltung Karlsruhe</li>
            <li>Kunsthochschule Kassel</li>
            <li>Muthesius Kunsthochschule Kiel</li>
            <li>Kunsthochschule für Medien Köln</li>
            <li>Hochschule für Grafik und Buchkunst Leipzig</li>
            <li>Kunsthochschule Mainz</li>
            <li>Akademie der Bildenden Künste München</li>
            <li>Kunstakademie Münster</li>
            <li>Akademie der Bildenden Künste Nürnberg</li>
            <li>Hochschule für Gestaltung Offenbach am Main</li>
            <li>Filmuniversität Babelsberg Konrad Wolf, Potsdam</li>
            <li>Hochschule der Bildenden Künste Saar</li>
            <li>Staatliche Akademie der Bildenden Künste Stuttgart</li>
            <li>Bauhaus-Universität Weimar</li>
          </ul>
        </ContentOverlay>
      </div>
      <div className="col-span-full md:hidden lg:col-span-4 lg:block">
        <ContentOverlay>
          <p>
            Teilnahmeberechtigt sind die 24 Akademien, Hochschulen und
            Universitäten der Rektorenkonferenz der deutschen Kunsthochschulen
            (RKK):
          </p>
          <ul className="pt-4">
            <li>Universität der Künste Berlin</li>
            <li>Weißensee Kunsthochschule Berlin</li>
            <li>Hochschule für Bildende Künste Braunschweig</li>
            <li>Hochschule für Künste Bremen</li>
            <li>Hochschule für Bildende Künste Dresden</li>
            <li>Kunstakademie Düsseldorf</li>
            <li>
              Staatliche Hochschule für Bildende Künste &ndash; Städelschule,
              Frankfurt am Main
            </li>
            <li>Burg Giebichenstein Kunsthochschule Halle</li>
            <li>Hochschule für bildende Künste Hamburg</li>
            <li>Staatliche Akademie der Bildenden Künste Karlsruhe</li>
            <li>Staatliche Hochschule für Gestaltung Karlsruhe</li>
            <li>Kunsthochschule Kassel</li>
            <li>Muthesius Kunsthochschule Kiel</li>
            <li>Kunsthochschule für Medien Köln</li>
            <li>Hochschule für Grafik und Buchkunst Leipzig</li>
            <li>Kunsthochschule Mainz</li>
            <li>Akademie der Bildenden Künste München</li>
            <li>Kunstakademie Münster</li>
            <li>Akademie der Bildenden Künste Nürnberg</li>
            <li>Hochschule für Gestaltung Offenbach am Main</li>
            <li>Filmuniversität Babelsberg Konrad Wolf, Potsdam</li>
            <li>Hochschule der Bildenden Künste Saar</li>
            <li>Staatliche Akademie der Bildenden Künste Stuttgart</li>
            <li>Bauhaus-Universität Weimar</li>
          </ul>
        </ContentOverlay>
      </div>
    </>
  );
}
