import { useState } from "react";
import NavLink from "./NavLink";
import cc from "classcat";
import NavCloseBtn from "./NavCloseBtn";

export default function Navbar({ menuContentOpen, closeMenuContent }) {
  const [open, setOpen] = useState(false);

  return (
    <nav className="slideInFromBottom fixed bottom-0 z-10 flex w-screen flex-col justify-start bg-light-gray font-roumald-bold text-sm uppercase text-black drop-shadow-overlay backdrop-blur transition-[top] duration-300 md:flex-row md:justify-around lg:text-lg">
      <div className="hidden h-14 w-full items-center justify-items-center sm:mx-8 md:grid md:grid-cols-6 lg:mx-12">
        <NavLink href="/bundespreis">Bundespreis</NavLink>
        <NavLink href="/ausstellung">Ausstellung</NavLink>
        <NavLink href="/nominierte">Nominierte</NavLink>
        <NavLink href="/katalog">Katalog</NavLink>
        <NavLink href="/archiv">Archiv</NavLink>
        <NavLink href="/impressum">Impressum</NavLink>
      </div>
      <div
        className={cc([
          "flex transition-all duration-1000 ease-in-out md:hidden",
          { "max-h-96": open, "max-h-20": !open },
        ])}
      >
        <NavCloseBtn
          onClick={() => {
            if (menuContentOpen) {
              closeMenuContent();
            } else {
              setOpen(!open);
            }
          }}
          showX={open || menuContentOpen}
        />
        {/* <button
          className="m-6 flex flex-col gap-2"
          onClick={() => {
            if (menuContentOpen) {
              closeMenuContent();
            } else {
              setOpen(!open);
            }
          }}
        >
          <div
            className={cc([
              "h-1 w-10 bg-black transition-all duration-1000",
              { "translate-y-3 -rotate-45": open || menuContentOpen },
            ])}
          ></div>
          <div
            className={cc([
              "h-1 w-10 bg-black transition-opacity duration-1000",
              { "opacity-0": open || menuContentOpen },
            ])}
          ></div>
          <div
            className={cc([
              "h-1 w-10 bg-black transition-all duration-1000",
              { "-translate-y-3 rotate-45": open || menuContentOpen },
            ])}
          ></div>
        </button> */}
        <div className="my-6 flex grow flex-col items-center text-2xl">
          <div className={cc(["-ml-20 flex flex-col gap-y-6"])}>
            <NavLink
              href="/bundespreis"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={0}
            >
              Bundespreis
            </NavLink>
            <NavLink
              href="/ausstellung"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={1}
            >
              Ausstellung
            </NavLink>
            <NavLink
              href="/nominierte"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={2}
            >
              Nominierte
            </NavLink>
            <NavLink
              href="/katalog"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={3}
            >
              Katalog
            </NavLink>
            <NavLink
              href="/archiv"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={4}
            >
              Archiv
            </NavLink>
            <NavLink
              href="/impressum"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={5}
            >
              Impressum
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
