import { useState } from "react";
import ArtistBig from "./ArtistBig";
import ArtistMobile from "./ArtistMobile";

export default function ArtistInFocus({
  artistId,
  winners,
  setArtist,
  showArtistCard,
  setShowArtistCard,
  prev,
  next,
}) {
  const [projectInFocus, setProjectInFocus] = useState(null);

  return (
    <>
      <ArtistBig
        artistId={artistId}
        winners={winners}
        setArtist={setArtist}
        showArtistCard={showArtistCard}
        setShowArtistCard={setShowArtistCard}
        prev={prev}
        projectInFocus={projectInFocus}
        setProjectInFocus={setProjectInFocus}
        next={next}
      />
      <ArtistMobile
        artistId={artistId}
        winners={winners}
        setArtist={setArtist}
        showArtistCard={showArtistCard}
        setShowArtistCard={setShowArtistCard}
        prev={prev}
        projectInFocus={projectInFocus}
        setProjectInFocus={setProjectInFocus}
        next={next}
      />
    </>
  );
}
