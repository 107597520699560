import { Fragment, useEffect, useMemo, useState } from "react";
import cc from "classcat";
import ReactMarkdown from "react-markdown";
import { useHotkeys } from "react-hotkeys-hook";
import ArtistCard from "./ArtistCard";
import ContentOverlay from "./ContentOverlay";

export default function ArtistBig({
  artistId,
  winners,
  setArtist,
  next,
  prev,
  showArtistCard,
  setShowArtistCard,
  projectInFocus,
  setProjectInFocus,
}) {
  const artist = winners.find((w) => w.id === artistId);

  const project = useMemo(
    () => artist.projects.find((p) => p.id === projectInFocus),
    [artist.projects, projectInFocus]
  );

  let imageSlices = [
    [0, 2],
    [2, 4],
    [4, 6],
  ];

  if (artistId === "feldman") {
    imageSlices = [
      [0, 2],
      [2, 3],
      [3, 6],
    ];
  }

  const spacers = {
    feldman: [["aspect-[2]"], [], ["aspect-[4.2]"]],
    goetsch: [[], [], ["aspect-[1.7]"]],
    kucera: [[], ["aspect-[1.5]"], []],
    su: [[], ["aspect-[1.45]"], []],
  };

  useHotkeys("Escape", () => {
    if (projectInFocus) {
      setProjectInFocus(null);
    } else {
      setArtist(null);
    }
  });

  useHotkeys("ArrowLeft", handlePrev);

  useHotkeys("ArrowRight", handleNext);

  function handleNext() {
    if (projectInFocus) {
      const pIndex = artist.projects.findIndex((p) => p.id === projectInFocus);
      setProjectInFocus(
        artist.projects[(pIndex + 1) % artist.projects.length].id
      );
    } else {
      setArtist(next);
    }
  }

  function handlePrev() {
    if (projectInFocus) {
      const pIndex = artist.projects.findIndex((p) => p.id === projectInFocus);
      if (pIndex === 0) {
        setProjectInFocus(artist.projects.at(-1).id);
      } else {
        setProjectInFocus(artist.projects[pIndex - 1].id);
      }
    } else {
      setArtist(prev);
    }
  }

  return (
    <div
      id="artist-container"
      className="hidden h-full flex-col backdrop-blur-sm lg:flex"
    >
      <div className="flex justify-between">
        <button
          className={cc([
            "pl-4 font-roumald-bold text-2xl transition-opacity",
            { "opacity-0": projectInFocus },
          ])}
          onClick={() => {
            setArtist(undefined);
          }}
          aria-label="close"
        >
          ↤
        </button>
        <h2 className="fadeIn text-right text-[72px] sm:px-8 lg:px-12">
          <span className="font-roumald-bold uppercase">
            <span className="pr-8 font-roumald-bolditalic">
              {artist.firstname}
            </span>
            {artist.lastname}
          </span>
        </h2>
      </div>
      <div
        className={cc([
          "relative grid grid-cols-3 overflow-auto pb-24 transition-all duration-300 sm:px-8 md:gap-4 md:pb-16 lg:h-screen lg:px-12",
          { "blur-sm": projectInFocus || showArtistCard },
        ])}
      >
        {imageSlices.map((s, colIdx) => (
          <div key={colIdx} className="flex flex-col gap-4">
            {artist.projects.slice(s[0], s[1]).map((project, idx) => (
              <Fragment key={idx}>
                {spacers[artistId]?.[colIdx]?.[idx] && (
                  <div className={spacers[artistId]?.[colIdx]?.[idx]}></div>
                )}
                <button
                  onClick={() => {
                    setProjectInFocus(project.id);
                  }}
                  key={idx}
                  aria-label={`view ${project.title}`}
                >
                  <img
                    id={`pile-${artist.lastname}-${idx}`}
                    className="fadeIn select-none"
                    src={project.image}
                    alt={project.title}
                  ></img>
                </button>
              </Fragment>
            ))}
          </div>
        ))}
      </div>
      <div
        className={cc([
          "pointer-events-none absolute inset-0 flex items-center justify-center px-12 pt-28 pb-20 opacity-0 transition-all duration-300",
          {
            "pointer-events-auto opacity-100": projectInFocus,
            blur: showArtistCard,
          },
        ])}
        onClick={(e) => {
          setProjectInFocus(null);
        }}
      >
        <img
          src={project?.image}
          className="h-full max-w-full select-none object-contain"
          alt={project?.title}
        ></img>
      </div>
      <DescriptionCard bigImage={projectInFocus} project={project} />
      <div
        className={cc([
          "pointer-events-none absolute inset-0 grid grid-cols-6 place-content-end px-12 pt-28 pb-20 opacity-0 transition-all duration-500",
          { "pointer-events-auto opacity-100": showArtistCard },
        ])}
        onClick={() => {
          setShowArtistCard(null);
        }}
      >
        <ArtistCard
          show={showArtistCard === "portrait"}
          className="col-start-2"
        >
          <img
            src={artist.portrait}
            alt={`portrait-${artist.name}`}
            className="max-h-[65vh] object-contain"
          />
        </ArtistCard>

        <ArtistCard show={showArtistCard === "bio"} className="col-start-3">
          <ContentOverlay>
            <ReactMarkdown
              components={{
                h1: ({ children }) => (
                  <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                    {children}
                  </h4>
                ),
              }}
            >
              {artist.bio}
            </ReactMarkdown>
          </ContentOverlay>
        </ArtistCard>

        <ArtistCard
          show={showArtistCard === "exhibitions"}
          className="col-start-4"
        >
          <div className="h-[100vh] translate-y-8 overflow-y-auto pb-8 pt-16">
            <ContentOverlay>
              <ReactMarkdown
                components={{
                  h1: ({ children }) => (
                    <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                      {children}
                    </h4>
                  ),
                }}
              >
                {`# Einzelaustellungen

${artist.exhibitionsSingle}
`}
              </ReactMarkdown>
            </ContentOverlay>
            {artist.exhibitionsGroup && (
              <ContentOverlay>
                <ReactMarkdown
                  components={{
                    h1: ({ children }) => (
                      <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                        {children}
                      </h4>
                    ),
                  }}
                >
                  {`# Gruppenaustellungen

${artist.exhibitionsGroup}
`}
                </ReactMarkdown>
              </ContentOverlay>
            )}
          </div>
        </ArtistCard>

        <ArtistCard show={showArtistCard === "jury"} className="col-start-5">
          <div className="h-[100vh] translate-y-8 overflow-y-auto pb-8 pt-16">
            <ContentOverlay>
              <ReactMarkdown
                components={{
                  h1: ({ children }) => (
                    <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                      {children}
                    </h4>
                  ),
                  em: ({ children }) => (
                    <span className="font-roumald-bolditalic">{children}</span>
                  ),
                  p: ({ children }) => (
                    <p className="pt-4 first:pt-0">{children}</p>
                  ),
                }}
              >
                {artist.jury}
              </ReactMarkdown>
            </ContentOverlay>
          </div>
        </ArtistCard>
      </div>
      <button
        className="absolute left-4 top-1/2 z-10 -translate-y-1/2 font-roumald-bold text-2xl"
        onClick={handlePrev}
        aria-label={projectInFocus ? "previous project" : "previous artist"}
      >
        ←
      </button>
      <button
        className="absolute right-4 top-1/2 z-10 -translate-y-1/2 font-roumald-bold text-2xl"
        onClick={handleNext}
        aria-label={projectInFocus ? "next project" : "next artist"}
      >
        →
      </button>
    </div>
  );
}

function DescriptionCard({ bigImage, project }) {
  const [hideDescription, setHideDescription] = useState(false);
  const [description, setDescription] = useState(project?.description);
  const [title, setTitle] = useState(project?.title);

  useEffect(() => {
    if (bigImage) {
      setHideDescription(false);
      setDescription(project?.description);
      setTitle(project?.title);
    }
  }, [bigImage, project?.description, project?.title]);

  const renderLink = ({ href, children }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="before:font-roumald-bold before:content-['↘']"
      >
        {children}
      </a>
    );
  };

  return (
    <div
      className={cc([
        "absolute transition-all duration-500",
        { "pointer-events-none -translate-y-16 opacity-0": !bigImage },
      ])}
    >
      <div
        className={cc([
          "ml-12 mt-4 max-w-[75%] bg-off-white p-3 drop-shadow-md transition-all duration-500",
          { "-translate-y-16 opacity-0": hideDescription },
        ])}
      >
        <h3 className="font-roumald-bolditalic uppercase">{title}</h3>
        <div className="font-elza text-18 transition-all">
          <ReactMarkdown components={{ a: renderLink }}>
            {description}
          </ReactMarkdown>
        </div>
      </div>

      <button
        className={cc([
          "absolute top-4 left-6 font-roumald-bold text-2xl transition-all duration-500",
          { "rotate-180": hideDescription },
        ])}
        onClick={() => {
          setHideDescription(!hideDescription);
        }}
        aria-label="hide description"
      >
        ↑
      </button>
    </div>
  );
}
