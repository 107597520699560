import cc from "classcat";

export default function Pile({
  images,
  name,
  centerArtist,
  id,
  setCenterArtist,
}) {
  const firstname = name.split(" ")[0];
  const lastname = name.split(" ").slice(1).join(" ");

  return (
    <button
      className={cc([
        "group relative col-span-full mb-16 flex cursor-pointer flex-col-reverse items-center justify-center opacity-100 transition-all duration-700 md:col-span-4 lg:mb-0",
        {
          "lg:opacity-0": centerArtist !== null && centerArtist !== undefined,
          pileSlideInTopLeft: id === "feldman",
          pileSlideInTop: id === "fink",
          pileSlideInTopRight: id === "goetsch",
          pileSlideInBottomLeft: id === "kucera",
          pileSlideInBottom: id === "su",
          pileSlideInBottomRight: id === "ostov",
        },
      ])}
      onClick={() => {
        setCenterArtist(id);
      }}
    >
      <div className="w-[299px] text-2xl transition-all duration-300 group-hover:translate-y-0 group-hover:opacity-100 lg:-translate-y-16 lg:text-18 lg:opacity-0">
        <span className="font-roumald-bold uppercase">
          <span className="pr-2 font-roumald-bolditalic">{firstname}</span>
          {lastname}
        </span>
      </div>
      <div className="relative h-[270px] w-[299px]">
        {images.map((image, idx) => (
          <img
            id={`pile-${lastname}-${idx}`}
            key={idx}
            className={cc([
              "absolute h-[35%] transition-all duration-300 group-hover:h-[40%]",
              { "top-0 left-1/4": idx === 0 },
              { "left-0 top-8": idx === 1 },
              { "top-16 right-16 h-[35%] group-hover:top-16": idx === 2 },
              { "top-24 right-0 group-hover:top-24": idx === 3 },
              { "top-32 left-12 group-hover:top-32": idx === 4 },
              {
                "top-40 left-[10%] group-hover:bottom-0 group-hover:left-0":
                  idx === 5,
              },
            ])}
            src={image}
            alt={`${name} ${idx}`}
          />
        ))}
      </div>
    </button>
  );
}
