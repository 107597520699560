import cc from "classcat";

export default function ContentOverlay({ children, className }) {
  return (
    <div
      className={cc([
        "m-1 h-fit bg-off-white p-3 font-elza text-18 drop-shadow-overlay sm:m-2",
        className,
      ])}
    >
      {children}
    </div>
  );
}
