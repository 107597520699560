import cc from "classcat";

export default function NavCloseBtn({ onClick, showX }) {
  return (
    <button className="m-6 flex flex-col gap-2" onClick={onClick}>
      <div
        className={cc([
          "h-1 w-10 bg-black transition-all duration-1000",
          { "translate-y-3 -rotate-45": showX },
        ])}
      ></div>
      <div
        className={cc([
          "h-1 w-10 bg-black transition-opacity duration-1000",
          { "opacity-0": showX },
        ])}
      ></div>
      <div
        className={cc([
          "h-1 w-10 bg-black transition-all duration-1000",
          { "-translate-y-3 rotate-45": showX },
        ])}
      ></div>
    </button>
  );
}
