import cc from "classcat";

export default function ArtistCard({ children, show, className }) {
  return (
    <div
      className={cc([
        "col-span-2 row-start-1 flex flex-col justify-end transition-all duration-500",
        className,
        { "pointer-events-none translate-y-1/2 opacity-0": !show },
      ])}
    >
      {children}
    </div>
  );
}
