import { useState } from "react";
import NavLink from "./NavLink";
import cc from "classcat";
import NavButton from "./NavButton";
import NavCloseBtn from "./NavCloseBtn";

export default function ArtistNavbar({
  setCenterArtist,
  showArtistCard,
  setShowArtistCard,
}) {
  const [open, setOpen] = useState(false);

  return (
    <nav className="slideInFromBottomArtistNav fixed bottom-0 z-10 flex w-screen flex-col justify-start bg-light-gray font-roumald-bold text-sm uppercase text-black drop-shadow-overlay backdrop-blur transition-[top] duration-300 lg:flex-row lg:justify-around lg:text-lg">
      <div className="hidden h-14 w-full items-center justify-items-center lg:mx-12 lg:grid lg:grid-cols-6">
        <div className="flex items-center">
          <button
            onClick={() => {
              setCenterArtist(undefined);
            }}
            className="pr-4 pb-1 text-[20px]"
            aria-label="close"
          >
            ↤
          </button>
          <NavLink href="/bundespreis">Bundespreis</NavLink>
        </div>
        <NavButton
          isActive={showArtistCard === "portrait"}
          onClick={() =>
            setShowArtistCard(showArtistCard === "portrait" ? null : "portrait")
          }
        >
          Portrait
        </NavButton>
        <NavButton
          isActive={showArtistCard === "bio"}
          onClick={() =>
            setShowArtistCard(showArtistCard === "bio" ? null : "bio")
          }
        >
          Biographie
        </NavButton>
        <NavButton
          isActive={showArtistCard === "exhibitions"}
          onClick={() =>
            setShowArtistCard(
              showArtistCard === "exhibitions" ? null : "exhibitions"
            )
          }
        >
          Ausstellungen
        </NavButton>

        <NavButton
          isActive={showArtistCard === "jury"}
          onClick={() =>
            setShowArtistCard(showArtistCard === "jury" ? null : "jury")
          }
        >
          Jurierung
        </NavButton>
        <NavLink href="/impressum">Impressum</NavLink>
      </div>
      <div
        className={cc([
          "flex transition-all duration-1000 ease-in-out lg:hidden",
          { "max-h-96": open, "max-h-20": !open },
        ])}
      >
        <NavCloseBtn
          onClick={() => {
            setOpen(!open);
          }}
          showX={open}
        />

        <div className="my-6 flex grow flex-col items-center text-2xl">
          <div className={cc(["-ml-20 flex flex-col gap-y-6"])}>
            <NavLink
              href="/bundespreis"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={0}
            >
              Bundespreis
            </NavLink>
            <NavLink
              href="/ausstellung"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={1}
            >
              Ausstellung
            </NavLink>
            <NavLink
              href="/nominierte"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={2}
            >
              Nominierte
            </NavLink>
            <NavLink
              href="/katalog"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={3}
            >
              Katalog
            </NavLink>
            <NavLink
              href="/archiv"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={4}
            >
              Archiv
            </NavLink>
            <NavLink
              href="/impressum"
              onClick={() => {
                setOpen(false);
              }}
              open={open}
              collapseMode
              idx={5}
            >
              Impressum
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
}
