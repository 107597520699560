import cc from "classcat";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function ArtistMobile({
  artistId,
  winners,
  setArtist,
  next,
  prev,
  showArtistCard,
  setShowArtistCard,
  projectInFocus,
  setProjectInFocus,
}) {
  const artist = winners.find((w) => w.id === artistId);

  const renderLink = ({ href, children }) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noreferrer"
        className="before:font-roumald-bold before:content-['↘']"
      >
        {children}
      </a>
    );
  };

  return (
    <div className="col-span-full h-full overflow-auto pb-20 lg:hidden">
      <div className="sticky top-0 flex h-20 items-center justify-between bg-off-white p-4 opacity-90 backdrop-blur-sm">
        <button
          className={cc([
            "font-roumald-bold text-2xl transition-opacity",
            { "opacity-0": projectInFocus },
          ])}
          onClick={() => {
            setArtist(undefined);
          }}
          aria-label="close"
        >
          ↤
        </button>
        <h2 className="fadeIn text-right text-2xl sm:px-8 sm:text-3xl md:text-5xl lg:px-12">
          <span className="font-roumald-bold uppercase">
            <span className="pr-4 font-roumald-bolditalic sm:pr-5 md:pr-7">
              {artist?.firstname}
            </span>
            {artist?.lastname}
          </span>
        </h2>
      </div>
      <div className="flex flex-col px-4 sm:px-8 md:px-16">
        {artist?.projects?.map((project) => (
          <>
            <MobileCard>
              <img className="" src={project.image} alt={project.title}></img>
            </MobileCard>
            <MobileCard className="p-2">
              <h3 className="font-roumald-bolditalic uppercase">
                {project.title}
              </h3>
              <ReactMarkdown components={{ a: renderLink }}>
                {project.description}
              </ReactMarkdown>
            </MobileCard>
          </>
        ))}
        <h3 className="mt-16 text-right font-roumald-bolditalic text-2xl uppercase sm:text-3xl md:text-5xl">
          Portrait
        </h3>
        <MobileCard>
          <img className="" src={artist.portrait} alt={artist.name}></img>
        </MobileCard>
        <h3 className="mt-16 text-right font-roumald-bolditalic text-2xl uppercase sm:text-3xl md:text-5xl">
          Biographie
        </h3>
        <MobileCard className="p-2">
          <ReactMarkdown
            components={{
              h1: ({ children }) => (
                <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                  {children}
                </h4>
              ),
            }}
          >
            {artist.bio}
          </ReactMarkdown>
        </MobileCard>
        <h3 className="mt-16 text-right font-roumald-bolditalic text-2xl uppercase sm:text-3xl md:text-5xl">
          Einzelaustellungen
        </h3>
        <MobileCard className="p-2">
          <ReactMarkdown
            components={{
              h1: ({ children }) => (
                <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                  {children}
                </h4>
              ),
            }}
          >
            {artist.exhibitionsSingle}
          </ReactMarkdown>
        </MobileCard>
        <h3 className="mt-16 text-right font-roumald-bolditalic text-2xl uppercase sm:text-3xl md:text-5xl">
          Jurierung
        </h3>
        <MobileCard className="p-2">
          <ReactMarkdown
            components={{
              h1: ({ children }) => (
                <h4 className="pt-4 font-roumald-bolditalic text-18 uppercase first:pt-0">
                  {children}
                </h4>
              ),
              em: ({ children }) => (
                <span className="font-roumald-bolditalic">{children}</span>
              ),
              p: ({ children }) => (
                <p className="pt-4 first:pt-0">{children}</p>
              ),
            }}
          >
            {artist.jury}
          </ReactMarkdown>
        </MobileCard>
      </div>
    </div>
  );
}

function MobileCard({ children, className }) {
  return (
    <div
      className={cc(["my-2 w-fit bg-off-white font-elza shadow-lg", className])}
    >
      {children}
    </div>
  );
}
