import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./fonts/Elza/Elza-Regular.woff";
import "./fonts/Elza/Elza-Regular.woff2";

import "./fonts/Roumald/EKRoumald-Bold.woff";
import "./fonts/Roumald/EKRoumald-Bold.woff2";

import "./fonts/Roumald/EKRoumald-BoldItalic.woff";
import "./fonts/Roumald/EKRoumald-BoldItalic.woff2";

import "./fonts/Roumald/EKRoumald-Italic.woff";
import "./fonts/Roumald/EKRoumald-Italic.woff2";

import "./fonts/Roumald/EKRoumald-Roman.woff";
import "./fonts/Roumald/EKRoumald-Roman.woff2";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
